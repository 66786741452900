import * as React from "react";
import { Link, graphql } from "gatsby";
import _ from "lodash";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { BlogPost as VoyagerBlogPost } from "@launchacademy/voyager";
import { parseISO } from "date-fns";
import buildImage from "../../services/buildImage";

import "../../css/richContent.css";

const BlogPost = ({ location, data }) => {
  const article = { socialUrls: [] };
  if (data.article) {
    const { article } = data;
    const modifiedArticle = {
      ...article,
      author: {
        ...article?.author,
        Image: buildImage(article?.author?.picture?.localFile),
      },
      Image: buildImage(article?.image?.localFile),
    };

    return (
      <Layout>
        <Seo
          title={modifiedArticle.title}
          pathname={location.pathname}
          description={
            modifiedArticle.description ||
            _.truncate(modifiedArticle.content.replace(/(<([^>]+)>)/gi, ""), { length: 130 })
          }
        />
        <div className="constrained-container blog-post">
          <ul className="breadcrumbs">
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to={`/blog/category/${article.category.slug}`}>{article.category.name}</Link>
            </li>
          </ul>
          <VoyagerBlogPost
            blogTitle={modifiedArticle.title}
            Image={modifiedArticle.Image}
            authorName={modifiedArticle.author?.name}
            AuthorImage={modifiedArticle.author.Image}
            url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
            publishedAt={article.publishedAt ? parseISO(article.publishedAt) : null}
          >
            <div className="rich-content" dangerouslySetInnerHTML={{ __html: article.content }} />
          </VoyagerBlogPost>
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

export default BlogPost;

export const query = graphql`
  query BlogPostQuery($slug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    article: strapiArticle(slug: { eq: $slug, ne: "" }) {
      slug
      title
      content
      description
      published_at
      author {
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 60, formats: [AUTO])
            }
          }
        }
        name
      }
      category {
        id
        name
        slug
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
