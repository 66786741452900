import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const buildImage = (gatsbyImageData) => {
  return (props) => {
    if(gatsbyImageData) {
      return <GatsbyImage image={getImage(gatsbyImageData)} {...props} />
    }
    return null
  }
}

export default buildImage
